import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import {
  mq,
  spacing,
  styled,
  Text,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/enterprise-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { Direction } from '@thg-commerce/gravity-elements/Product/types'
import { Alignment } from '@thg-commerce/gravity-patterns/Header/types'
import { GridItem, Picture } from '@thg-commerce/gravity-system'
import {
  BreakpointArray,
  mediaQueryRenderer,
} from '@thg-commerce/gravity-theme'

import { ItemAlignment, SurfaceBorder, SurfaceStyle } from './types'

export const Container = styled.div<{
  surfaceStyle: SurfaceStyle
}>`
  ${(props) =>
    props.surfaceStyle === 'Background' &&
    `
      background: ${(props) =>
        props.theme.widget.imageTextBlock.container.backgroundColor};
    `}
`

export const Title = styled.h2<{
  horizontalAlignment?: HorizontalAlignment
  textColour?: string
}>`
  ${(props) =>
    Text(
      props.theme.widget.imageTextBlock.title.font.entry,
      props.theme.widget.imageTextBlock.title.font.style,
      props.theme.widget.imageTextBlock.title.font.hasScaledText,
    )};
  text-transform: ${(props) =>
    props.theme.widget.imageTextBlock.title.font.transform};
  text-decoration: ${(props) =>
    props.theme.widget.imageTextBlock.title.font.textDecoration};
  color: ${(props) =>
    props.textColour || props.theme.widget.imageTextBlock.title.font.textColor};
  text-align: ${(props) =>
    props.horizontalAlignment === Alignment.center ? 'center' : 'baseline'};
  margin-bottom: ${spacing(2)};
`

export const Content = styled(SafeHtml)<{
  horizontalAlignment?: HorizontalAlignment
  textColour?: string
}>`
  ${Text('bodyText', 'default')};
  text-align: ${(props) =>
    props.horizontalAlignment === Alignment.center ? 'center' : 'baseline'};
  color: ${(props) =>
    props.textColour || props.theme.widget.imageTextBlock.title.font.textColor};
  margin-bottom: ${spacing(4)};

  h1 {
    ${(props) =>
      Text(
        props.theme.widget.imageTextBlock.title.font.entry,
        props.theme.widget.imageTextBlock.title.font.style,
        props.theme.widget.imageTextBlock.title.font.hasScaledText,
      )};
  }
`

export const ContentContainer = styled.div<{
  surfaceStyle: SurfaceStyle
  directions: BreakpointArray<Direction>
  border?: SurfaceBorder
  horizontalAlignment?: Alignment
  backgroundColour?: string
  textColour?: string
  hasMargin?: string
  hasPadding?: string
}>`
  align-items: ${(props) => props.horizontalAlignment || 'center'};
  text-align: ${(props) => props.horizontalAlignment || 'center'};
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColour || 'transparent'};
  ${(props) =>
    props.directions &&
    mediaQueryRenderer(
      props.directions,
      (direction) => `flex-direction :${direction}`,
    )}

  padding: ${(props) =>
    props.hasPadding === 'top'
      ? `${spacing(2)} 0 0 0`
      : props.hasPadding === 'bottom'
      ? `0 0 ${spacing(2)} 0`
      : props.hasPadding === 'left'
      ? `0 0 0 ${spacing(2)}`
      : props.hasPadding === 'right'
      ? `0 ${spacing(2)} 0 0`
      : props.hasPadding === 'all'
      ? `${spacing(2)}`
      : '0'};

  margin-top: ${(props) =>
    props.hasMargin === 'top' || props.hasMargin === 'both'
      ? `${spacing(4)};`
      : '0'};
  margin-bottom: ${(props) =>
    props.hasMargin === 'bottom' || props.hasMargin === 'both'
      ? `${spacing(4)};`
      : '0'};

  ${(props) =>
    props.surfaceStyle === 'Background' &&
    `margin-left: ${spacing(2)}; margin-right: ${spacing(2)};`}
  ${(props) =>
    props.border &&
    `
    border: ${props.border.width} ${props.border.style} ${
      props.border.color || props.theme.colors.palette.greys.light
    };
  
  `}
`

export const ImageContainer = styled.div<{
  flexBasis: BreakpointArray<string>
  orders?: BreakpointArray<number>
}>`
  width: 100%;
  margin: 0;
  aspect-ratio: auto 2 / 1;

  ${(props) =>
    mediaQueryRenderer(props.flexBasis, (basis) => `flex-basis: ${basis};`)}

  ${(props) =>
    props.orders &&
    mediaQueryRenderer(props.orders, (order) => `order: ${order};`)}
`

export const TextContainer = styled.div<{
  flexBasis: BreakpointArray<string>
  surfaceStyle: SurfaceStyle
  textAlign: ItemAlignment
  verticalAlignment?: string
  orders?: BreakpointArray<number>
}>`
  display: flex;
  flex-direction: column;
  text-align: ${(props) => props.textAlign || 'center'};
  margin: 0;
  padding: ${spacing(4)} ${spacing(2)};
  width: 100%;
  aspect-ratio: auto;

  justify-content: ${(props) =>
    props.verticalAlignment ? props.verticalAlignment : 'center'};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${spacing(3)};
  }

  ${(props) =>
    props.surfaceStyle === 'TextOverlay' &&
    `
      background: ${(props) => props.theme.colors.palette.greys.lighter};
      ${zIndex(ZIndexLevel.Low)};
    `}

  ${(props) =>
    mediaQueryRenderer(props.flexBasis, (basis) => `flex-basis: ${basis};`)}

    ${(props) =>
    props.orders &&
    mediaQueryRenderer(props.orders, (order) => `order: ${order};`)}
`

export const TextGridItem = styled(GridItem)<{
  surfaceStyle: SurfaceStyle
  verticalAlignment?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.verticalAlignment};
  text-align: center;
  margin: 0;
  padding: ${spacing(4)} ${spacing(2)};
  width: 100%;
  aspect-ratio: auto 2 / 1;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${spacing(3)};
  }

  ${(props) =>
    props.surfaceStyle === 'TextOverlay' &&
    `
      background: ${(props) => props.theme.colors.palette.greys.lighter};
      ${zIndex(ZIndexLevel.Low)};
    `}
`

export const CTAWrapper = styled.div<{
  ctaHorizontalAlignment?: HorizontalAlignment
}>`
  display: flex;
  justify-content: ${(props) =>
    props.ctaHorizontalAlignment === 'left'
      ? 'flex-start'
      : props.ctaHorizontalAlignment === 'right'
      ? 'flex-end'
      : 'center'};
  flex-wrap: wrap;
`

export const StyledButton = styled(Button)`
  margin: ${spacing(1)};
`

export const ProvenanceBrandHeaderComponent = styled.div`
  padding-top: ${spacing(5)};
`

export const GlobalVideoTextImaCtaPictureLink = styled.a`
  width: 100%
  display: flex;
`

export const GlobalVideoTextImaCtaPicture = styled(Picture)`
  max-width: 100%;
  margin: 0 auto ${spacing(5)};
`

export const ImageAnnotationText = styled.div`
  p {
    ${Text('small', 'alternate')}
  }
  text-align: left;
  display: flex;
  a {
    ${Text('small', 'alternate')}
    text-decoration: none;
    outline-offset: 2px;
    color: ${(props) => props.theme.colors.palette.brand.base};

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline-color: ${(props) => props.theme.colors.palette.brand.base};
    }
  }
`
